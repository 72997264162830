.Container{
    width: 100%;
    overflow: visible;
}

.Slider{
    position: relative;
    width: 100%;
    text-align: center;
    display: block;
    height: 3.25rem;
    overflow: visible;
}

.Header{
    font-size: var(--font-size-extra-extra-large);
    color: var(--text-color-dark);
}

.Span{
    position: relative;
    width: max-content;
    width: 100%;
    text-align: center;
    color: var(--text-color-black);
    font-size: var(--font-size-huge);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* transform: translateX(-50%); */
}

.Selected{
    display: block;
    position: absolute;
    animation: enter 1.5s forwards;
    width: max-content;

    /* color: var(--primary-color); */
}

.Previous{
    /* top: 0; */
    display: block;
    position: absolute;
    animation: exit 1s forwards;
    width: max-content;

    /* color: var(--primary-color); */
}

.Hidden{
    /* top: 0; */
    position: absolute;
    display: none;
    opacity: 0;
    width: max-content;

}

@keyframes enter {
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0
    }
    100%{
        opacity: 1;
    }
}

@keyframes exit {
    0%{
        opacity: 1;
    }
    20%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

/* @keyframes enter {
    0%{
        left: 150%;
        opacity: .5;
    }
    100%{
        left: 50%;
        opacity: 1;
    }
}

@keyframes exit {
    0%{
        top: 0;
        left: 50%;
        opacity: 1;
    }
    20%{
        opacity: .2;
    }
    100%{
        top: 0;
        left: -100%;
        opacity: 0;
    }
} */