.FileInput{
    cursor: pointer;
    display: none;
}

.FileInputLabel{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin: 0 var(--margin-main);
    text-align: center;
}