.Section{
    /* background-color: var(--background-color-white); */
    position: relative;
    height: 150%;
    width: 100%;
    padding: var(--padding-heavy) 5%;
    box-sizing: border-box;
    margin: var(--margin-main) 0;
}

.Panel{
    min-width: 30%;
    max-width: 50%;
    width: min-content;
    height: auto;
    max-height: calc(100% - var(--padding-heavy) * 2);
}

.Panel h2{
    margin-top: 0;
}

.ImageContainer{
    width: 80%;
    height: 100%;
    position: relative;
    /* overflow: hidden; */
}

.Image{ 
    display: block;
    margin: auto;
    max-width: 100%;
    height: 100%;
    max-height: 65vh;
    object-fit: scale-down;
    object-position: center center;
}

.Linking .ImageContainer{
    width: 60%;
    margin-left: auto;
    right: 10%;
}

.Linking .Image{
    max-height: 60vh;
}

.LinkingPanel{
    left: 10%;
    padding: var(--padding-extra-heavy);
    max-height: unset;
}

.ViewPanel{
    right: 10%;
}

.View .ImageContainer{
    left: 5%;
}

.Final .ImageContainer{
    width: fit-content;
    margin-left: 50%;
}

.Final .Image{
    max-height: 50vh;
}

.FinalPanel{
    right: 50%;
}

@media screen and (max-width: 900px) {
    .ImageContainer{
        /* position: absolute; */
        left: unset !important;
        right: unset !important;
        margin: auto !important;
    }

    .Panel{
        top: unset;
        width: unset;
        transform: unset;
        left: unset;
        right: unset;
        background-color: transparent;
        box-shadow: none;
        padding: var(--padding-main);
        margin: var(--margin-heavy) auto;
        margin-bottom: 0;
        max-width: 75%;
    }

    .Panel::after{
        position: relative;
        content: '';
        float: left;
        height: 150%;
    }

    .Section{
    }

    .Linking .ImageContainer{
        width: 100%;
    }

    .LinkingPanel{
        display: block;
        position: relative;
        /* transform: translate(0, 50%); */
        height: 100%;
    }

    .View .ImageContainer{
        width: 100%;
    }

    .ViewPanel{
        position: relative;
        /* transform: translate(0, 50%); */
    }

    .FinalPanel{
        position: relative;
        /* transform: translate(0, 50%); */
    }

    .Final .ImageContainer{
        max-width: 20rem;
    }
}