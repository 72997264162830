.Confirm{
    background-color: var(--background-color-white);
    color: var(--text-color-dark);;
    padding: var(--padding-heavy);
    width: max-content;
    border-radius: var(--border-radius-main);
}

.ConfirmTitle{
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
}

.FileTitle{
    display: block;
}

.Form{
}

.Form>label{
    margin: 0;
}