.SharpIcon{
    fill: white;
    stroke-width: 1.5rem;
}

.Icon{
    fill: white;
    stroke-width: 1rem;
    overflow: visible;
    /* color: var(--text-color-black); */
}

.Menu{
    height: 2rem;
    width: 2rem;
    stroke-width: 8px;
}

.Black{
    fill: var(--text-color-black);
}

.NoFill{
    fill: transparent;
}