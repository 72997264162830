.Container{
    position: absolute;
    height: auto;
    /* max-height: 50vh; */
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* overflow: hidden; */
}

.Container .Image{
    width: auto;
    margin: auto;
    top: 0;
    left: 0;
    transform: unset;
}

.Actions{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Button{
    position: relative;
    display: flex;
    flex-direction: row !important;
    padding: var(--padding-main);
    margin: var(--margin-main);
}

.Button svg{
    margin: var(--margin-light);
}

.Confirm{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
}

.Loader{
    position: relative !important;
}