@import './variables/variables.css';
@import './variables/colors.css';
@import './variables/text.css';
@import './variables/mediaSizes.css';

body{
    text-rendering: optimizeLegibility;
}

body *{
    box-sizing: border-box;
}

a{
    text-decoration: none;
}

ul, li { 
    margin: 0;
    padding-left: 0; 
}