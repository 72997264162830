.Item{
    background-color: var(--background-color-white);
    /* Don't set position or context menu won't work */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.ThreeDots{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1px 2px;
    padding: var(--padding-light);
    z-index: 1;
}

.ThreeDots svg{
    /* position: absolute; */
    stroke: white;
    fill: var(--text-color-black);
    height: 1.5rem;
    width: 1.5rem;
    stroke-width: 1.5rem;
}

.Child .ThreeDots{
    width: 1rem;
    height: 1.25rem;
}

.Icon{
    margin: 0 auto;
    display: flex;
    position: relative;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    /* height: 5rem; */
    /* font-size: 2rem; */

    user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;

    -webkit-touch-callout: none !important;
    z-index: 0;
}


/* USE THIS OVERLAY TO PREVENT LONG-PRESS DEFAULT ACTION ON MOBILE */
.Icon:before{
    content: '';
    padding-top: 56.25%;
    float: left;
    width: 100%;
    top: 0;
    min-height: 100%;
    box-sizing: border-box;
    z-index: 1;
}

.IconImage, .Icon img{
    user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;

    -webkit-touch-callout: none !important;

    position: absolute;
    top: 50%;
    left: 0;
    
    /* SET HEIGHT WIDTH >100% TO PREVENT BLANK LINE FROM SHOWING UP ON BORDER */
    width: 101%; 
    height: 101%;

    object-fit: cover;
    object-position: center center;
    transform: translateY(-50%);
    margin: 0;
    font-size: 0; /*To Hide Alt Tag*/
}

.Icon .Hidden{
    visibility: hidden;
}

.DefaultIcon{
    height: 75%;
    stroke-width: 8px !important;
    max-height: 4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: white;
    color: var(--text-color-dark);;
    margin: 0 !important;
}

.Collage{
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    z-index: 0;
    align-self: stretch;
    min-height: 100%;
    max-height: 100%;
}

.Collage::before{
    content: '';
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: var(--background-color-dark-transparent);
}

.Collage .Icon{
    position: relative;
    flex: 1 1 50%;
    height: auto;
    margin: 0;
}

/* .Collage .Icon img{
    object-fit: cover;
} */

.CollageOverlay{
    width: auto;
    position: absolute !important;
    top: 100%;
    left: 100%;
    transform: translate(-100%, -100%);
    z-index: 1;
    height: 50%;
    margin: 0;
}

.CollageOverlay::after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--background-color-white-transparent);
}

.AttachedIndicators{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-100%)
}

.AttachedIndicator{
    /* margin: 0 var(--margin-main); */
    position: relative;
}

.NoteIndicator{
    margin-right: auto;
}

.IndicatorIcon{
    margin: 0 var(--margin-main);
    height: 1.5rem;
    width: 1.5rem;
}

.Peek{
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(00%, -50%);
    visibility: hidden;
    opacity: 0;
    transition: all .5s;
    transition-delay: .25s;
    width: 10rem;
    max-height: 10rem;
    overflow: auto;
    z-index: 1;
    box-shadow: var(--box-shadow-main);
}

.NotePeek{
    height: 10rem;
}

.FilePeek{
    left: unset;
    right: 100%;
    background-color: white;
    text-align: center;
}

.FilePreview{
    width:100%;
    height: auto;
    text-align: center;
}

.AttachedIndicator:hover .Peek{
    visibility: visible;
    opacity: 1;
    /* transform: translate(-100%, -50%); */
    transition: all .5s;
    transition-delay: .5s;
}

.Details{
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;
    width: 100%;
}

.Title{
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: unset;
    margin: var(--margin-light);
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 100%;
}

.Description{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: var(--text-color-medium);
    text-overflow: ellipsis;
    margin: var(--margin-light);
    font-weight: 300;
}

.Tag{
    display: inline-block;
    width: fit-content;
    color: var(--primary-color);
    margin: 1px;
    cursor: pointer;
    padding: 1px;
}

.EditTag{
    background-color: var(--primary-color);
    color: white;
    border-radius: var(--border-radius-sharp);
}

.EditTag:hover,.SelectedTag{
    background-color: var(--accent-color-primary);
    color: var(--text-color-black);
}

.ActiveTag{
    background-color: var(--primary-color);
    color: white;
}

.Item .ActiveTag{
    background-color: unset;
    color: var(--primary-color);
    /* font-weight: bold; */
    text-decoration: underline;
}

.Tags{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: var(--margin-light);
    margin-bottom: 0;
}

.URLDBContainer{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.URL{
    font-size: var(--font-size-small);
    color: var(--text-color-medium);
    margin: var(--margin-light);
    max-width: 75%;
    overflow: hidden;
    /* margin-right: auto; */
}

.URL:hover{
    color: var(--primary-color);
}

.URLText{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
}


.DBStatus{
    font-size: var(--font-size-small);
    color: var(--text-color-dark);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: var(--margin-light);
}   

.Favorite{
    top: 0;
    left: 0;
    margin: 0;
    z-index: 1;
}

.StarFill{
    fill: var(--text-color-black) !important;
}

.Note{
    background-color: var(--background-color-white);
    padding: var(--padding-main);
    align-self: top;
    border: none;
    /* border-radius: var(--border-radius-main); */
    outline: none;
    white-space: normal;
    line-break: auto;
    word-wrap: break-word;
    cursor: text;
}

.Placeholder::before{
    content: 'Add a Note';
    color: var(--text-color-light);
}

.Note:focus{
    cursor: text;
    overflow-y: auto;
    overflow-wrap: break-word;
    outline: none;
}

.Note:focus::before{
    content: '';
}

.Loader{
    position: absolute;
    animation: rotate 1s infinite linear;
}

/* CHILDREN */

.ChildrenContainer{
    box-sizing: border-box;
    width: 100%;
}

.Children{
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: var(--margin-main);
    max-height: auto;
    box-sizing: border-box;
    align-items: flex-start;
    align-content: flex-start;
}

.ExpandedChildren{
    position: absolute;
    bottom: 0;
    left: 0;
    max-height:100%;
    min-height: 100%;
    margin: 0;
    box-sizing: border-box;
    background-color: var(--background-color-white);
    overflow: auto;
    border-radius: var(--border-radius-main);
    z-index: 10;
    animation: fadeIn .25s forwards;
}

@keyframes fadeIn {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }


.OverflowChildren{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--margin-light) auto;
    padding: var(--padding-main);
    border-radius: var(--border-radius-main);
    background-color: var(--background-color-white);
    cursor: pointer;
    width: 50%;
    height: 3rem;
    box-sizing: border-box;
    /* box-shadow: var(--box-shadow-main); */
    border: var(--border-medium);
}

.OverflowChildren .ChildTitle{
    margin: 0;
}

.OverflowTitle{
    position: sticky;
    top: 0;
    width: 100%;
    text-align: center;
    /* background-color: white; */
    z-index: 2;
    /* padding: var(--padding-main); */
    margin: 0;
    padding: var(--padding-main);
    background-color: white;
}

.OverflowTitle>*{
    box-sizing: border-box;
    max-width: 100%;
    width: fit-content;
    margin: auto;
    border-radius: var(--border-radius-main);
}

.OverflowChildren:hover{
    background-color: var(--primary-color);
    color: white;
}

.BottomPadding{
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 3.5rem;
}

.ExpandedChildren~.OverflowChildren{
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 10;
    transform: translateX(-50%);
}

.ChildContextMenu{
    font-size: var(--font-size-small);
}

.ChildWrapper{
    /* flex: 0 1 auto; */
    position: relative;
    display: flex;
    flex-direction: column;
    margin: var(--margin-light);
    box-sizing: border-box;
    width: calc(48% - var(--margin-light) - var(--margin-light));
    border-radius: .25rem;
    border: var(--border-medium);
    background-color: var(--background-color-white);
    cursor: pointer;
    height: auto;
    padding-bottom: var(--padding-light);
    
}

.ChildWrapper::before{
    content: '';
    float: left;
    padding-top: 92.5%;
}

.Child{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
}


.ChildIcon{
    border-radius: var(--border-radius-main);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    position: relative;
    order: 1;
}

.ChildTitle{
    position: relative;
    /* flex: 0 1 auto; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: var(--font-size-small);
    order: 2;
    text-align: center;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: auto;
    margin: var(--margin-light);
}

.Empty{
    background-color: transparent;
    /* height: auto; */
    width: 100%;
    align-self: stretch;
    cursor: auto;

}

.Empty:only-child{
    height: 0;
    display: none;
}

.HoverContainer{
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: var(--background-color-white-translucent);
    font-size: var(--font-size-small);
}

.Child:hover .HoverContainer{
    visibility: visible;
    opacity: 1;
    transition: opacity .25s;
}


@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* MAX WIDTH OF 500PX IS INTENTIONAL */
@media screen and (max-width: 500px) {
    .ChildWrapper{
        flex: 0 1 90%;
        padding-bottom: 0;
    }

    .ChildWrapper::before{
        display: none;
    }

    .Child{
        position: relative;
        flex-direction: row;
        align-items: stretch;
        height: 3.5rem;
        padding-right: 1rem; /* FOR THREE DOTS ICON */
    }

    .Child .ChildIcon{
        flex: 0 1 40%;
    }

    .Child .Wrapper::before{
        padding-top: unset;
    }

    .ChildTitle{
        flex: 1 0 60%;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        align-self: center;
        /* text-align: left; */
        margin: var(--margin-light);
    }

    .ChildTitle span{
        max-width: 100% !important;
        word-wrap: break-word !important;
    }

    .Child .ThreeDots{
        margin-right: 0;
        padding: 0;
    }

    .Child .ThreeDots svg{
        width: 1rem;
    }
}