.Button {
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--border-radius-main);
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: var(--padding-main) var(--padding-heavy);
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: var(--margin-main);
    box-shadow: var(--box-shadow-main-offset);
    transition: all .05s;
}

.Button:active{
    transform: translate(0px, 1px);
    transition: all .05s;
    box-shadow: var(--box-shadow-main-offset-active);

}

/* .Button:first-of-type {
    margin-left: 0;
} */

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Button svg{
    margin: var(--margin-light);
}

.Continue {
    /* color: #5C9210; */
    flex: 2 0 auto;
}

.Cancel{
    flex: 1 1 auto;
    background-color: var(--background-color-light);
    color: var(--text-color-black);
}

.Delete {
    background-color: var(--accent-color-red-medium);
    flex: 1 1 auto;
    /* color: var(--text-color-black); */
}

.Delete svg{
    color: white;
}

