/* MEDIA QUERIES */
/*
0 - 600px (37.5em): Phone
600 - 900px (56.25em): Tablet Portrait
900 - 1200px (75em): Table land
1200 - 1800px (112.5em): Desktop (Normal)
1800px + : Big Desktop

$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

*/

:root{
    --phone-width: 600px;
    --tablet-width: 900px;
    --tablet-landscape-width: 1200px;
    --desktop-width: 1800px;
    --large-desktop-width: 2400px;
}