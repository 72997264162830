.Main{
    position: relative;
    min-height: 100%;
    margin: auto;
}


.NavBar{
    position: sticky;
    top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-direction: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    padding: var(--padding-main) 0;
    padding-bottom: var(--padding-heavy);
    background-color: var(--background-color-white);
    z-index: 1000;

    height: 5rem;
    width: 100%;
    transition: all .25s;
}

.Content{
    position: relative;
    padding-top: 0;
    transition: all .25s;
    min-height: calc(100vh - 5rem);
}

.Scrolled .NavBar{
    height: 3rem;
    padding: 0;
    padding-bottom: var(--padding-main);
    transition: all .25s;
}

.Scrolled .Content{
    padding-top: 3rem;
    transition: all .25s;
}


.Logo{
    position: relative;
    margin-left: 15vw;
    width: fit-content;
    /* height: 100%; */
    font-family: 'obvia';
    /* font-family: 'puffin-display'; */
}

.Logo h1{
    height: 100%;
    text-align: end;
}

.Logo img{
    height: 100%;
    object-fit: scale-down;
}

.Main .NavButtons{
    justify-content: end;
    margin: 0;
    margin-right: 15vw;
    width: auto;
}

.Main img{
    font-size: 0; /*Hide alt tags*/
}

.NavButton{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: var(--padding-main) var(--padding-main); */
    /* background-color: var(--primary-color); */
    border-radius: var(--border-radius-main);
    /* color: white; */
    font-size: var(--font-size-medium);
    margin: 0 var(--margin-main);
}

.NavButton:hover{
    color: var(--primary-color);
}

.NavButton svg{
    margin-right: var(--margin-main)
}

.Footer{
    padding: var(--padding-main);
    text-align: center;
}

.Footer p{
    display: block;
}

.Email{
    color: var(--primary-color);
}

.Faded{
    color: var(--text-color-light);
}

.Copyright{
    color: var(--text-color-light);
    margin: var(--margin-heavy);
}

.Attribution{
    margin-bottom: 0;
    color: var(--text-color-dark);
}

@media screen and (max-width: 900px) {
    .NavBar{
        position: sticky;
        top: 0;
    }
}