:root{
    /* PADDING & MARGINS */

    --padding-main: .5rem;
    --padding-light: .25rem;
    --padding-heavy: 1rem;
    --padding-extra-heavy: 1.5rem;

    --margin-main: .5rem;
    --margin-light: .25rem;
    --margin-heavy: 1rem;

    /* BORDERS */
    --border-radius-main: .125rem;
    --border-radius-sharp: .075rem;
    --border-base: solid 1px;
    --border-light: solid 1px var(--text-color-light);
    --border-light-yellow: solid 1px var(--accent-color-primary);
    --border-medium: solid 1px var(--background-color-medium);

    /* & BOX SHADOWS */

    --box-shadow-main: 0px 0px 10px 1px rgba(52, 62, 61, 0.15);
    --box-shadow-main-offset: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    --box-shadow-main-offset-active: rgba(0, 0, 0, 0.24) 0px 1px 1px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    --box-shadow-highlight: 0px 0px 5px 2px var(--accent-color-primary-translucent);
    --box-shadow-base: 0px 0px 5px 2px;
    --box-shadow-sharp-base: 0 2px 2px 1px;
    --box-shadow-sharp-main: var(--box-shadow-sharp-base) rgba(52,62,61,0.15);
    --box-shadow-tight: 0px 0px 2px 3px;
    --box-shadow-selected: 0 0px 0px 2px;

    --box-shadow-medium: 0px 5px 19px -2px rgba(0,0,0,0.25);
    

    /* Z-INDEXES */
    --z-index-flowView: 1;
    --z-index-ancestry: 10;
    --z-index-glimpseView: 11;
    --z-index-detailView: 100;
    --z-index-itemForm: 200;
    --z-index-modal: 300;
    --z-index-spinner: 400;
    --z-index-loading: 500;



    /* ELEMENT HEIGHTS/WIDTHS */
    --default-icon-height: 75%;
    --sidebar-root-height: 2rem;


    /* MOBILE */
    --mobile-header-height: 2.25rem;
}