.FirstView{
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    padding: 2.5%;
}

.Image{
    position: relative;
    display: block;
    height: 100%;
    max-height: 75vh;
    height: auto;
    max-width: 60%;
    object-position: center center;
    object-fit: scale-down;
    left: 40%;
}

.Details{
    padding: var(--padding-main);
}


.MainPanel{
    position: absolute;
    top: 50%;
    right: 60%;
    transform: translateY(-50%);
    text-align: center;
    width: max-content;
    background-color: white;
    padding: var(--padding-heavy) var(--padding-extra-heavy);
}

.Active{
    opacity: 1;
    transition: all .5s;
}

.FirstView .Button{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-main) var(--padding-main);
    border-radius: var(--border-radius-main);
    font-size: var(--font-size-medium-large);
    margin: 0 var(--margin-main);
    padding: var(--padding-main) var(--padding-extra-heavy);
    background-color: var(--primary-color);
    color: white;
    width: max-content;
    justify-content: center;
}

.FirstView .Button:hover{
    color: white;
}

.FirstView .ButtonContainer{
    margin: var(--margin-heavy) 0 !important;
    justify-content: center !important;
}



@media screen and (max-width: 900px) {
    .FirstView{
        padding: var(--padding-heavy);
    }

    .FirstView .Button{
        width: max-content;
    }

    .MainPanel{
        left: 5%;
        background-color: var(--background-color-white-translucent);
    }
}

@media screen and (max-width: 600px) {
    .Image{
        left: 0;
        margin: auto;
        max-width: 80%;
    }


    .MainPanel{
        position: relative !important;
        display: block;
        right: unset;
        top: unset;
        transform: unset;
        width: max-content;
        max-width: 100%;
        margin: auto;
        /* margin: var(--margin-main); */
        padding: var(--padding-main);
        left: 0;
    }
}

@media screen and (max-width: 450px){
    .Image{
        max-width: 90%;
    }
}