.Panel{
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: var(--background-color-white-opaque);
    padding: var(--padding-extra-heavy);

    /* box-shadow: var(--box-shadow-medium); */
    border-radius: var(--border-radius-main);

    text-align: left;
}

.Title{
    margin: var(--margin-heavy) auto;
    font-size: var(--font-size-extra-large);
    color: var(--primary-color);
    text-align: center;
}

.Description{
    font-size: var(--font-size-medium-large);
}

@media screen and (max-width: 900px) {

    .Title{
        margin: var(--margin-main) auto;
    }

    .Panel{
        background-color: inherit;
    }
}