.Container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    width: 100%;
    margin: var(--margin-main) 0;

    cursor: auto;
}