.Modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    z-index: var(--z-index-modal);
    max-width: 50%;
    overflow: auto;
}

.Background{
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: calc(var(--z-index-modal) - 1);
    background-color: var(--background-color-dark-translucent);
}

@media screen and (max-width:1200px) {
    .Modal{
        max-width: 75%;
    }
}

@media screen and (max-width:600px) {
    .Modal{
        max-width: 95%;
    }

}