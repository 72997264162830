@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
@import url('https://use.typekit.net/mos4egv.css');

body{
    font-family: 'proxima-nova','PT Sans', sans-serif;
    line-height: 1.15;
    scroll-behavior: smooth;
    font-weight: 300;
    font-size: var(--font-size-main);
    color: var(--text-color-dark);
}

.App{
    position: relative;
    height: 100%;
    width: 100%;
}

.App .Pending{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: var(--z-index-spinner);
    /* background-color: var(--background-color-white-translucent); */
}

.App .Dots{
    background-color: var(--accent-color-primary);
    /* background-color: var(--primary-color); */
}