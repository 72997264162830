html{
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

html *{
  scrollbar-width: thin;
}

html *::-webkit-scrollbar {
      width: .5rem;
   }

html *::-webkit-scrollbar-track {
    background-color: white;
  }

html *::-webkit-scrollbar-thumb {
    background: var(--background-color-medium);
    border-radius: 25px;
}

body {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding:0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

h1,h2,h3,h4,h5,p{
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

#root{
  position: relative;
  height: 100%;
  width: 100%;
}