.Loading {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  z-index: var(--z-index-loading);
}
.Loading div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--accent-color-primary);
  animation: loading 1.2s linear infinite;
}
.Loading div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.Loading div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.Loading div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.Loading div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.Loading div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.Loading div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.Loading div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.Loading div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.Loading div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes loading {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
