.Modal{
    /* background-color: white; */
    /* z-index: 0; */
    position: fixed;
}

.Background{
    position: fixed;
    height: 100%;
}

.ErrorContainer{
    display: block;
    margin: auto;
    background-color: white;
    padding: var(--padding-extra-heavy);
    border-radius: var(--border-radius-main);

    width: 100%;
    max-width: 100%;
}

.Message{
    display: block;
    margin: var(--margin-heavy) auto;
    text-align: center;
    font-size: var(--font-size-medium-large);
    padding: 0 var(--padding-extra-heavy);
    width: 80%;
    max-width: 100%;
}

.Message span{
    display: block;
}

.ImageContainer{
    width: 100%;
    padding: var(--padding-heavy);
}

.Image{
    width: 100%;
}

.Details{

}

@media screen and (max-width: 600px) {
    .Modal{
        width: max-content;
    }

    .ErrorContainer{
        width: max-content;
    }

    .Message{
        width: max-content;
    }
}