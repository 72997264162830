.Wrapper{ 
    position: relative;
    height: auto;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    /* overflow: hidden; */
}

.Image{
    max-width: 100%;
    max-height: 90%;
    object-fit: scale-down;
    object-position: center;
    max-height: 50vh;
    text-align: center;
}

.Text{
    font-weight: 400;
    text-align: center;
}