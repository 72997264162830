:root{
    /* TEXT */
    --font-size-main: 1rem;
    --font-size-xsmall: .5rem;
    --font-size-small: .8rem;
    --font-size-medium-small: .9rem;
    --font-size-medium: 1rem;
    --font-size-medium-large: 1.25rem;
    --font-size-large: 1.5rem;
    --font-size-extra-large: 2rem;
    --font-size-extra-extra-large: 2.5rem;
    --font-size-huge: 3rem;  

    --text-color-light: #a7a7a7;
    --text-color-medium-light: #7e7e7e;
    --text-color-medium:#585858;
    --text-color-dark: #292929;
    --text-color-black: #151515;
    --text-color-white: #F8F6F1;

    --font-family-main: 'proxima-nova','PT Sans', sans-serif;
}