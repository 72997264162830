:root{
    /* COLORS */
    /* --background-color-light: #dbdbdb; */
    --background-color-extra-light: #F3F4F6;
    --background-color-light: #DCDDE5;
    --background-color-light-translucent: #DCDDE5cc;
    --background-color-light-transparent: #DCDDE57e;
    --background-color-medium: #A3A3A3;
    --background-color-medium-translucent: #A3A3A3cc;
    --background-color-medium-transparent: #A3A3A37e;

    --background-color-dark: #38383D;
    --background-color-dark-translucent: #38383Da6;
    --background-color-dark-transparent: #38383D4f;

    /* --background-color-light: 
    --background-color-light-translucent:  */
    --background-color-white: #FFFEFF;
     /* #F8F6F1; */
    --background-color-white-opaque: #ffffffda;
    --background-color-white-translucent: #ffffffb0;
    /* #f8f6f1b2; */
    --background-color-white-transparent: #ffffffc2;

    --primary-color: #0090C1;
    --primary-color-light: #25a5cf;
    --primary-color-translucent: #0090c1cc;
    --primary-color-dark: #003D52;
    --accent-color-primary: #FDC835;
    --accent-color-primary-translucent: #FDC835;
    --accent-color-secondary: #6C6ADC;
    --accent-color-secondary-translucent: #6c6adc4b;

    --accent-color-red: #E7480D;
    --accent-color-red-medium: #e9532d;
    --accent-color-red-light: #ff987e;
    --accent-color-orange: #F18F01;
    --accent-color-green: #29BF12;


    --star-filled: black;
}