.Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: var(--padding-extra-heavy);
    text-align: center;
    border-radius: var(--border-radius-main);
    box-shadow: var(--box-shadow-main-offset);
    margin: auto;
}

.Header{
    margin: var(--margin-heavy) 0;
    font-size: var(--font-size-large);
    width: 100%;
    overflow: hidden;
    font-weight: 400;
}

.Title{
    width: 100%;
    display: block;
    word-break: normal;
    margin-top: var(--margin-main);
    font-weight: 600;

}

.Dialog{
    
}

.Buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.SingleVsBranch{
    flex: 2 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.Button{
    /* flex: 1 0 auto; */
    margin: var(--margin-main);
    /* max-width: 60%; */
}

@media screen and (max-width:600px) {
    .SingleVsBranch{
        /* flex-direction: column; */
        width: 100%;
    }

    .Buttons{
        /* flex-direction: column; */
        align-items: center;
        width: fit-content;
    }

    .Button{
        max-width: unset;
    }

    .Container{
        width: max-content;
        /* min-width: 20rem; */
        max-width: 90%;
        margin: auto;

    }
}