@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@import url(https://use.typekit.net/mos4egv.css);
:root{
    /* PADDING & MARGINS */

    --padding-main: .5rem;
    --padding-light: .25rem;
    --padding-heavy: 1rem;
    --padding-extra-heavy: 1.5rem;

    --margin-main: .5rem;
    --margin-light: .25rem;
    --margin-heavy: 1rem;

    /* BORDERS */
    --border-radius-main: .125rem;
    --border-radius-sharp: .075rem;
    --border-base: solid 1px;
    --border-light: solid 1px var(--text-color-light);
    --border-light-yellow: solid 1px var(--accent-color-primary);
    --border-medium: solid 1px var(--background-color-medium);

    /* & BOX SHADOWS */

    --box-shadow-main: 0px 0px 10px 1px rgba(52, 62, 61, 0.15);
    --box-shadow-main-offset: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    --box-shadow-main-offset-active: rgba(0, 0, 0, 0.24) 0px 1px 1px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    --box-shadow-highlight: 0px 0px 5px 2px var(--accent-color-primary-translucent);
    --box-shadow-base: 0px 0px 5px 2px;
    --box-shadow-sharp-base: 0 2px 2px 1px;
    --box-shadow-sharp-main: var(--box-shadow-sharp-base) rgba(52,62,61,0.15);
    --box-shadow-tight: 0px 0px 2px 3px;
    --box-shadow-selected: 0 0px 0px 2px;

    --box-shadow-medium: 0px 5px 19px -2px rgba(0,0,0,0.25);
    

    /* Z-INDEXES */
    --z-index-flowView: 1;
    --z-index-ancestry: 10;
    --z-index-glimpseView: 11;
    --z-index-detailView: 100;
    --z-index-itemForm: 200;
    --z-index-modal: 300;
    --z-index-spinner: 400;
    --z-index-loading: 500;



    /* ELEMENT HEIGHTS/WIDTHS */
    --default-icon-height: 75%;
    --sidebar-root-height: 2rem;


    /* MOBILE */
    --mobile-header-height: 2.25rem;
}
:root{
    /* COLORS */
    /* --background-color-light: #dbdbdb; */
    --background-color-extra-light: #F3F4F6;
    --background-color-light: #DCDDE5;
    --background-color-light-translucent: #DCDDE5cc;
    --background-color-light-transparent: #DCDDE57e;
    --background-color-medium: #A3A3A3;
    --background-color-medium-translucent: #A3A3A3cc;
    --background-color-medium-transparent: #A3A3A37e;

    --background-color-dark: #38383D;
    --background-color-dark-translucent: #38383Da6;
    --background-color-dark-transparent: #38383D4f;

    /* --background-color-light: 
    --background-color-light-translucent:  */
    --background-color-white: #FFFEFF;
     /* #F8F6F1; */
    --background-color-white-opaque: #ffffffda;
    --background-color-white-translucent: #ffffffb0;
    /* #f8f6f1b2; */
    --background-color-white-transparent: #ffffffc2;

    --primary-color: #0090C1;
    --primary-color-light: #25a5cf;
    --primary-color-translucent: #0090c1cc;
    --primary-color-dark: #003D52;
    --accent-color-primary: #FDC835;
    --accent-color-primary-translucent: #FDC835;
    --accent-color-secondary: #6C6ADC;
    --accent-color-secondary-translucent: #6c6adc4b;

    --accent-color-red: #E7480D;
    --accent-color-red-medium: #e9532d;
    --accent-color-red-light: #ff987e;
    --accent-color-orange: #F18F01;
    --accent-color-green: #29BF12;


    --star-filled: black;
}
:root{
    /* TEXT */
    --font-size-main: 1rem;
    --font-size-xsmall: .5rem;
    --font-size-small: .8rem;
    --font-size-medium-small: .9rem;
    --font-size-medium: 1rem;
    --font-size-medium-large: 1.25rem;
    --font-size-large: 1.5rem;
    --font-size-extra-large: 2rem;
    --font-size-extra-extra-large: 2.5rem;
    --font-size-huge: 3rem;  

    --text-color-light: #a7a7a7;
    --text-color-medium-light: #7e7e7e;
    --text-color-medium:#585858;
    --text-color-dark: #292929;
    --text-color-black: #151515;
    --text-color-white: #F8F6F1;

    --font-family-main: 'proxima-nova','PT Sans', sans-serif;
}
/* MEDIA QUERIES */
/*
0 - 600px (37.5em): Phone
600 - 900px (56.25em): Tablet Portrait
900 - 1200px (75em): Table land
1200 - 1800px (112.5em): Desktop (Normal)
1800px + : Big Desktop

$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

*/

:root{
    --phone-width: 600px;
    --tablet-width: 900px;
    --tablet-landscape-width: 1200px;
    --desktop-width: 1800px;
    --large-desktop-width: 2400px;
}
body{
    text-rendering: optimizeLegibility;
}

body *{
    box-sizing: border-box;
}

a{
    text-decoration: none;
}

ul, li { 
    margin: 0;
    padding-left: 0; 
}
html{
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

html *{
  scrollbar-width: thin;
}

html *::-webkit-scrollbar {
      width: .5rem;
   }

html *::-webkit-scrollbar-track {
    background-color: white;
  }

html *::-webkit-scrollbar-thumb {
    background: var(--background-color-medium);
    border-radius: 25px;
}

body {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding:0;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

h1,h2,h3,h4,h5,p{
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

#root{
  position: relative;
  height: 100%;
  width: 100%;
}
.FirstView_FirstView__124fl{
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: relative;
    padding: 2.5%;
}

.FirstView_Image__1kJPW{
    position: relative;
    display: block;
    height: 100%;
    max-height: 75vh;
    height: auto;
    max-width: 60%;
    object-position: center center;
    object-fit: scale-down;
    left: 40%;
}

.FirstView_Details__1gn8u{
    padding: var(--padding-main);
}


.FirstView_MainPanel___4Emy{
    position: absolute;
    top: 50%;
    right: 60%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
    width: -webkit-max-content;
    width: max-content;
    background-color: white;
    padding: var(--padding-heavy) var(--padding-extra-heavy);
}

.FirstView_Active__2yhLa{
    opacity: 1;
    transition: all .5s;
}

.FirstView_FirstView__124fl .FirstView_Button__4qxZu{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--padding-main) var(--padding-main);
    border-radius: var(--border-radius-main);
    font-size: var(--font-size-medium-large);
    margin: 0 var(--margin-main);
    padding: var(--padding-main) var(--padding-extra-heavy);
    background-color: var(--primary-color);
    color: white;
    width: -webkit-max-content;
    width: max-content;
    justify-content: center;
}

.FirstView_FirstView__124fl .FirstView_Button__4qxZu:hover{
    color: white;
}

.FirstView_FirstView__124fl .FirstView_ButtonContainer__1dL21{
    margin: var(--margin-heavy) 0 !important;
    justify-content: center !important;
}



@media screen and (max-width: 900px) {
    .FirstView_FirstView__124fl{
        padding: var(--padding-heavy);
    }

    .FirstView_FirstView__124fl .FirstView_Button__4qxZu{
        width: -webkit-max-content;
        width: max-content;
    }

    .FirstView_MainPanel___4Emy{
        left: 5%;
        background-color: var(--background-color-white-translucent);
    }
}

@media screen and (max-width: 600px) {
    .FirstView_Image__1kJPW{
        left: 0;
        margin: auto;
        max-width: 80%;
    }


    .FirstView_MainPanel___4Emy{
        position: relative !important;
        display: block;
        right: unset;
        top: unset;
        -webkit-transform: unset;
                transform: unset;
        width: -webkit-max-content;
        width: max-content;
        max-width: 100%;
        margin: auto;
        /* margin: var(--margin-main); */
        padding: var(--padding-main);
        left: 0;
    }
}

@media screen and (max-width: 450px){
    .FirstView_Image__1kJPW{
        max-width: 90%;
    }
}
.TextSlider_Container__2wA4f{
    width: 100%;
    overflow: visible;
}

.TextSlider_Slider__2e0Ms{
    position: relative;
    width: 100%;
    text-align: center;
    display: block;
    height: 3.25rem;
    overflow: visible;
}

.TextSlider_Header__zIj0C{
    font-size: var(--font-size-extra-extra-large);
    color: var(--text-color-dark);
}

.TextSlider_Span__1IoHA{
    position: relative;
    width: -webkit-max-content;
    width: max-content;
    width: 100%;
    text-align: center;
    color: var(--text-color-black);
    font-size: var(--font-size-huge);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* transform: translateX(-50%); */
}

.TextSlider_Selected___OIlB{
    display: block;
    position: absolute;
    -webkit-animation: TextSlider_enter__11hL0 1.5s forwards;
            animation: TextSlider_enter__11hL0 1.5s forwards;
    width: -webkit-max-content;
    width: max-content;

    /* color: var(--primary-color); */
}

.TextSlider_Previous__2JPr5{
    /* top: 0; */
    display: block;
    position: absolute;
    -webkit-animation: TextSlider_exit__3KQJO 1s forwards;
            animation: TextSlider_exit__3KQJO 1s forwards;
    width: -webkit-max-content;
    width: max-content;

    /* color: var(--primary-color); */
}

.TextSlider_Hidden__3Oe4U{
    /* top: 0; */
    position: absolute;
    display: none;
    opacity: 0;
    width: -webkit-max-content;
    width: max-content;

}

@-webkit-keyframes TextSlider_enter__11hL0 {
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0
    }
    100%{
        opacity: 1;
    }
}

@keyframes TextSlider_enter__11hL0 {
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0
    }
    100%{
        opacity: 1;
    }
}

@-webkit-keyframes TextSlider_exit__3KQJO {
    0%{
        opacity: 1;
    }
    20%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@keyframes TextSlider_exit__3KQJO {
    0%{
        opacity: 1;
    }
    20%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

/* @keyframes enter {
    0%{
        left: 150%;
        opacity: .5;
    }
    100%{
        left: 50%;
        opacity: 1;
    }
}

@keyframes exit {
    0%{
        top: 0;
        left: 50%;
        opacity: 1;
    }
    20%{
        opacity: .2;
    }
    100%{
        top: 0;
        left: -100%;
        opacity: 0;
    }
} */
.ButtonContainer_Container__3IuWS{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;

    width: 100%;
    margin: var(--margin-main) 0;

    cursor: auto;
}
.Panel_Panel__2hq55{
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 30%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: var(--background-color-white-opaque);
    padding: var(--padding-extra-heavy);

    /* box-shadow: var(--box-shadow-medium); */
    border-radius: var(--border-radius-main);

    text-align: left;
}

.Panel_Title__2bH34{
    margin: var(--margin-heavy) auto;
    font-size: var(--font-size-extra-large);
    color: var(--primary-color);
    text-align: center;
}

.Panel_Description__3Ze0M{
    font-size: var(--font-size-medium-large);
}

@media screen and (max-width: 900px) {

    .Panel_Title__2bH34{
        margin: var(--margin-main) auto;
    }

    .Panel_Panel__2hq55{
        background-color: inherit;
    }
}
.Highlights_Section__1-Y2D{
    /* background-color: var(--background-color-white); */
    position: relative;
    height: 150%;
    width: 100%;
    padding: var(--padding-heavy) 5%;
    box-sizing: border-box;
    margin: var(--margin-main) 0;
}

.Highlights_Panel__u5Cqo{
    min-width: 30%;
    max-width: 50%;
    width: -webkit-min-content;
    width: min-content;
    height: auto;
    max-height: calc(100% - var(--padding-heavy) * 2);
}

.Highlights_Panel__u5Cqo h2{
    margin-top: 0;
}

.Highlights_ImageContainer__2cs_f{
    width: 80%;
    height: 100%;
    position: relative;
    /* overflow: hidden; */
}

.Highlights_Image__2XDRw{ 
    display: block;
    margin: auto;
    max-width: 100%;
    height: 100%;
    max-height: 65vh;
    object-fit: scale-down;
    object-position: center center;
}

.Highlights_Linking__2zY0e .Highlights_ImageContainer__2cs_f{
    width: 60%;
    margin-left: auto;
    right: 10%;
}

.Highlights_Linking__2zY0e .Highlights_Image__2XDRw{
    max-height: 60vh;
}

.Highlights_LinkingPanel__LniA5{
    left: 10%;
    padding: var(--padding-extra-heavy);
    max-height: unset;
}

.Highlights_ViewPanel__1klCO{
    right: 10%;
}

.Highlights_View__39Hme .Highlights_ImageContainer__2cs_f{
    left: 5%;
}

.Highlights_Final__1gY-H .Highlights_ImageContainer__2cs_f{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 50%;
}

.Highlights_Final__1gY-H .Highlights_Image__2XDRw{
    max-height: 50vh;
}

.Highlights_FinalPanel__kpvdx{
    right: 50%;
}

@media screen and (max-width: 900px) {
    .Highlights_ImageContainer__2cs_f{
        /* position: absolute; */
        left: unset !important;
        right: unset !important;
        margin: auto !important;
    }

    .Highlights_Panel__u5Cqo{
        top: unset;
        width: unset;
        -webkit-transform: unset;
                transform: unset;
        left: unset;
        right: unset;
        background-color: transparent;
        box-shadow: none;
        padding: var(--padding-main);
        margin: var(--margin-heavy) auto;
        margin-bottom: 0;
        max-width: 75%;
    }

    .Highlights_Panel__u5Cqo::after{
        position: relative;
        content: '';
        float: left;
        height: 150%;
    }

    .Highlights_Section__1-Y2D{
    }

    .Highlights_Linking__2zY0e .Highlights_ImageContainer__2cs_f{
        width: 100%;
    }

    .Highlights_LinkingPanel__LniA5{
        display: block;
        position: relative;
        /* transform: translate(0, 50%); */
        height: 100%;
    }

    .Highlights_View__39Hme .Highlights_ImageContainer__2cs_f{
        width: 100%;
    }

    .Highlights_ViewPanel__1klCO{
        position: relative;
        /* transform: translate(0, 50%); */
    }

    .Highlights_FinalPanel__kpvdx{
        position: relative;
        /* transform: translate(0, 50%); */
    }

    .Highlights_Final__1gY-H .Highlights_ImageContainer__2cs_f{
        max-width: 20rem;
    }
}
.HomeLayout_Main__1rqXs{
    position: relative;
    min-height: 100%;
    margin: auto;
}


.HomeLayout_NavBar__PQ_RF{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-direction: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    padding: var(--padding-main) 0;
    padding-bottom: var(--padding-heavy);
    background-color: var(--background-color-white);
    z-index: 1000;

    height: 5rem;
    width: 100%;
    transition: all .25s;
}

.HomeLayout_Content__3vwWs{
    position: relative;
    padding-top: 0;
    transition: all .25s;
    min-height: calc(100vh - 5rem);
}

.HomeLayout_Scrolled__3QhrK .HomeLayout_NavBar__PQ_RF{
    height: 3rem;
    padding: 0;
    padding-bottom: var(--padding-main);
    transition: all .25s;
}

.HomeLayout_Scrolled__3QhrK .HomeLayout_Content__3vwWs{
    padding-top: 3rem;
    transition: all .25s;
}


.HomeLayout_Logo__UiY2A{
    position: relative;
    margin-left: 15vw;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* height: 100%; */
    font-family: 'obvia';
    /* font-family: 'puffin-display'; */
}

.HomeLayout_Logo__UiY2A h1{
    height: 100%;
    text-align: end;
}

.HomeLayout_Logo__UiY2A img{
    height: 100%;
    object-fit: scale-down;
}

.HomeLayout_Main__1rqXs .HomeLayout_NavButtons__AN3kg{
    justify-content: end;
    margin: 0;
    margin-right: 15vw;
    width: auto;
}

.HomeLayout_Main__1rqXs img{
    font-size: 0; /*Hide alt tags*/
}

.HomeLayout_NavButton__3hlwg{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: var(--padding-main) var(--padding-main); */
    /* background-color: var(--primary-color); */
    border-radius: var(--border-radius-main);
    /* color: white; */
    font-size: var(--font-size-medium);
    margin: 0 var(--margin-main);
}

.HomeLayout_NavButton__3hlwg:hover{
    color: var(--primary-color);
}

.HomeLayout_NavButton__3hlwg svg{
    margin-right: var(--margin-main)
}

.HomeLayout_Footer__w2Rwq{
    padding: var(--padding-main);
    text-align: center;
}

.HomeLayout_Footer__w2Rwq p{
    display: block;
}

.HomeLayout_Email__3tZcJ{
    color: var(--primary-color);
}

.HomeLayout_Faded__2YBpO{
    color: var(--text-color-light);
}

.HomeLayout_Copyright__36tF6{
    color: var(--text-color-light);
    margin: var(--margin-heavy);
}

.HomeLayout_Attribution__3H6Zh{
    margin-bottom: 0;
    color: var(--text-color-dark);
}

@media screen and (max-width: 900px) {
    .HomeLayout_NavBar__PQ_RF{
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
}
.Icons_SharpIcon___MrHD{
    fill: white;
    stroke-width: 1.5rem;
}

.Icons_Icon__3__Wq{
    fill: white;
    stroke-width: 1rem;
    overflow: visible;
    /* color: var(--text-color-black); */
}

.Icons_Menu__2METR{
    height: 2rem;
    width: 2rem;
    stroke-width: 8px;
}

.Icons_Black__vnAoe{
    fill: var(--text-color-black);
}

.Icons_NoFill__1PXHh{
    fill: transparent;
}
.Loading_Loading__1Bfvh {
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  z-index: var(--z-index-loading);
}
.Loading_Loading__1Bfvh div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--accent-color-primary);
  -webkit-animation: Loading_loading__3Jaic 1.2s linear infinite;
          animation: Loading_loading__3Jaic 1.2s linear infinite;
}
.Loading_Loading__1Bfvh div:nth-child(1) {
  top: 8px;
  left: 8px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.Loading_Loading__1Bfvh div:nth-child(2) {
  top: 8px;
  left: 32px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.Loading_Loading__1Bfvh div:nth-child(3) {
  top: 8px;
  left: 56px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.Loading_Loading__1Bfvh div:nth-child(4) {
  top: 32px;
  left: 8px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.Loading_Loading__1Bfvh div:nth-child(5) {
  top: 32px;
  left: 32px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.Loading_Loading__1Bfvh div:nth-child(6) {
  top: 32px;
  left: 56px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.Loading_Loading__1Bfvh div:nth-child(7) {
  top: 56px;
  left: 8px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.Loading_Loading__1Bfvh div:nth-child(8) {
  top: 56px;
  left: 32px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.Loading_Loading__1Bfvh div:nth-child(9) {
  top: 56px;
  left: 56px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
}
@-webkit-keyframes Loading_loading__3Jaic {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes Loading_loading__3Jaic {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.Button_Button__3gFiX {
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--border-radius-main);
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: var(--padding-main) var(--padding-heavy);
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: var(--margin-main);
    box-shadow: var(--box-shadow-main-offset);
    transition: all .05s;
}

.Button_Button__3gFiX:active{
    -webkit-transform: translate(0px, 1px);
            transform: translate(0px, 1px);
    transition: all .05s;
    box-shadow: var(--box-shadow-main-offset-active);

}

/* .Button:first-of-type {
    margin-left: 0;
} */

.Button_Button__3gFiX:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Button_Button__3gFiX svg{
    margin: var(--margin-light);
}

.Button_Continue__2SFp3 {
    /* color: #5C9210; */
    flex: 2 0 auto;
}

.Button_Cancel__3kq9-{
    flex: 1 1 auto;
    background-color: var(--background-color-light);
    color: var(--text-color-black);
}

.Button_Delete__1cxvu {
    background-color: var(--accent-color-red-medium);
    flex: 1 1 auto;
    /* color: var(--text-color-black); */
}

.Button_Delete__1cxvu svg{
    color: white;
}


.ConfirmDialog_Container__2BWtx{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: var(--padding-extra-heavy);
    text-align: center;
    border-radius: var(--border-radius-main);
    box-shadow: var(--box-shadow-main-offset);
    margin: auto;
}

.ConfirmDialog_Header__3Kjcj{
    margin: var(--margin-heavy) 0;
    font-size: var(--font-size-large);
    width: 100%;
    overflow: hidden;
    font-weight: 400;
}

.ConfirmDialog_Title__3r38F{
    width: 100%;
    display: block;
    word-break: normal;
    margin-top: var(--margin-main);
    font-weight: 600;

}

.ConfirmDialog_Dialog__3I8tG{
    
}

.ConfirmDialog_Buttons__fE0Hb{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ConfirmDialog_SingleVsBranch__1crHA{
    flex: 2 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.ConfirmDialog_Button__2CIG2{
    /* flex: 1 0 auto; */
    margin: var(--margin-main);
    /* max-width: 60%; */
}

@media screen and (max-width:600px) {
    .ConfirmDialog_SingleVsBranch__1crHA{
        /* flex-direction: column; */
        width: 100%;
    }

    .ConfirmDialog_Buttons__fE0Hb{
        /* flex-direction: column; */
        align-items: center;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .ConfirmDialog_Button__2CIG2{
        max-width: unset;
    }

    .ConfirmDialog_Container__2BWtx{
        width: -webkit-max-content;
        width: max-content;
        /* min-width: 20rem; */
        max-width: 90%;
        margin: auto;

    }
}
.FileInput_FileInput__3Uvcb{
    cursor: pointer;
    display: none;
}

.FileInput_FileInputLabel__na4Wh{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin: 0 var(--margin-main);
    text-align: center;
}
.ImportBookmarksForm_Confirm__1aJEK{
    background-color: var(--background-color-white);
    color: var(--text-color-dark);;
    padding: var(--padding-heavy);
    width: -webkit-max-content;
    width: max-content;
    border-radius: var(--border-radius-main);
}

.ImportBookmarksForm_ConfirmTitle__v8b_D{
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
}

.ImportBookmarksForm_FileTitle__2av5J{
    display: block;
}

.ImportBookmarksForm_Form__1tCdt{
}

.ImportBookmarksForm_Form__1tCdt>label{
    margin: 0;
}
.Item_Item__317LJ{
    background-color: var(--background-color-white);
    /* Don't set position or context menu won't work */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Item_ThreeDots__w-_gj{
    position: absolute;
    top: 0;
    right: 0;
    margin: 1px 2px;
    padding: var(--padding-light);
    z-index: 1;
}

.Item_ThreeDots__w-_gj svg{
    /* position: absolute; */
    stroke: white;
    fill: var(--text-color-black);
    height: 1.5rem;
    width: 1.5rem;
    stroke-width: 1.5rem;
}

.Item_Child__3EBeI .Item_ThreeDots__w-_gj{
    width: 1rem;
    height: 1.25rem;
}

.Item_Icon__1HGT-{
    margin: 0 auto;
    display: flex;
    position: relative;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    /* height: 5rem; */
    /* font-size: 2rem; */

    -ms-user-select: none !important;

        user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;

    -webkit-touch-callout: none !important;
    z-index: 0;
}


/* USE THIS OVERLAY TO PREVENT LONG-PRESS DEFAULT ACTION ON MOBILE */
.Item_Icon__1HGT-:before{
    content: '';
    padding-top: 56.25%;
    float: left;
    width: 100%;
    top: 0;
    min-height: 100%;
    box-sizing: border-box;
    z-index: 1;
}

.Item_IconImage__8BMWu, .Item_Icon__1HGT- img{
    -ms-user-select: none !important;
        user-select: none !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;

    -webkit-touch-callout: none !important;

    position: absolute;
    top: 50%;
    left: 0;
    
    /* SET HEIGHT WIDTH >100% TO PREVENT BLANK LINE FROM SHOWING UP ON BORDER */
    width: 101%; 
    height: 101%;

    object-fit: cover;
    object-position: center center;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin: 0;
    font-size: 0; /*To Hide Alt Tag*/
}

.Item_Icon__1HGT- .Item_Hidden__27-Wf{
    visibility: hidden;
}

.Item_DefaultIcon__jBAMw{
    height: 75%;
    stroke-width: 8px !important;
    max-height: 4rem;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    fill: white;
    color: var(--text-color-dark);;
    margin: 0 !important;
}

.Item_Collage__3ICPv{
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    z-index: 0;
    align-self: stretch;
    min-height: 100%;
    max-height: 100%;
}

.Item_Collage__3ICPv::before{
    content: '';
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: var(--background-color-dark-transparent);
}

.Item_Collage__3ICPv .Item_Icon__1HGT-{
    position: relative;
    flex: 1 1 50%;
    height: auto;
    margin: 0;
}

/* .Collage .Icon img{
    object-fit: cover;
} */

.Item_CollageOverlay__2O3JF{
    width: auto;
    position: absolute !important;
    top: 100%;
    left: 100%;
    -webkit-transform: translate(-100%, -100%);
            transform: translate(-100%, -100%);
    z-index: 1;
    height: 50%;
    margin: 0;
}

.Item_CollageOverlay__2O3JF::after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--background-color-white-transparent);
}

.Item_AttachedIndicators__NZAce{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%)
}

.Item_AttachedIndicator__AllAF{
    /* margin: 0 var(--margin-main); */
    position: relative;
}

.Item_NoteIndicator__mpGmo{
    margin-right: auto;
}

.Item_IndicatorIcon__3XG1F{
    margin: 0 var(--margin-main);
    height: 1.5rem;
    width: 1.5rem;
}

.Item_Peek__2zEqt{
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translate(00%, -50%);
            transform: translate(00%, -50%);
    visibility: hidden;
    opacity: 0;
    transition: all .5s;
    transition-delay: .25s;
    width: 10rem;
    max-height: 10rem;
    overflow: auto;
    z-index: 1;
    box-shadow: var(--box-shadow-main);
}

.Item_NotePeek__9XYXs{
    height: 10rem;
}

.Item_FilePeek__3EPND{
    left: unset;
    right: 100%;
    background-color: white;
    text-align: center;
}

.Item_FilePreview__1m_LC{
    width:100%;
    height: auto;
    text-align: center;
}

.Item_AttachedIndicator__AllAF:hover .Item_Peek__2zEqt{
    visibility: visible;
    opacity: 1;
    /* transform: translate(-100%, -50%); */
    transition: all .5s;
    transition-delay: .5s;
}

.Item_Details__1vEdK{
    position: relative;
    overflow: hidden;
    flex: 0 0 auto;
    width: 100%;
}

.Item_Title__UlQBd{
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: unset;
    margin: var(--margin-light);
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 100%;
}

.Item_Description__1bzPy{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: var(--text-color-medium);
    text-overflow: ellipsis;
    margin: var(--margin-light);
    font-weight: 300;
}

.Item_Tag__3fyCF{
    display: inline-block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--primary-color);
    margin: 1px;
    cursor: pointer;
    padding: 1px;
}

.Item_EditTag__15iGI{
    background-color: var(--primary-color);
    color: white;
    border-radius: var(--border-radius-sharp);
}

.Item_EditTag__15iGI:hover,.Item_SelectedTag__D9RTT{
    background-color: var(--accent-color-primary);
    color: var(--text-color-black);
}

.Item_ActiveTag__w96x0{
    background-color: var(--primary-color);
    color: white;
}

.Item_Item__317LJ .Item_ActiveTag__w96x0{
    background-color: unset;
    color: var(--primary-color);
    /* font-weight: bold; */
    text-decoration: underline;
}

.Item_Tags__31Sg1{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: var(--margin-light);
    margin-bottom: 0;
}

.Item_URLDBContainer__1Bo_0{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
}

.Item_URL__3_aIl{
    font-size: var(--font-size-small);
    color: var(--text-color-medium);
    margin: var(--margin-light);
    max-width: 75%;
    overflow: hidden;
    /* margin-right: auto; */
}

.Item_URL__3_aIl:hover{
    color: var(--primary-color);
}

.Item_URLText__2h2xY{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
}


.Item_DBStatus__YxrOB{
    font-size: var(--font-size-small);
    color: var(--text-color-dark);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: var(--margin-light);
}   

.Item_Favorite__6e3xC{
    top: 0;
    left: 0;
    margin: 0;
    z-index: 1;
}

.Item_StarFill__1_Dr7{
    fill: var(--text-color-black) !important;
}

.Item_Note__kS3ty{
    background-color: var(--background-color-white);
    padding: var(--padding-main);
    align-self: top;
    border: none;
    /* border-radius: var(--border-radius-main); */
    outline: none;
    white-space: normal;
    line-break: auto;
    word-wrap: break-word;
    cursor: text;
}

.Item_Placeholder__2DBO3::before{
    content: 'Add a Note';
    color: var(--text-color-light);
}

.Item_Note__kS3ty:focus{
    cursor: text;
    overflow-y: auto;
    overflow-wrap: break-word;
    outline: none;
}

.Item_Note__kS3ty:focus::before{
    content: '';
}

.Item_Loader__1zOtB{
    position: absolute;
    -webkit-animation: Item_rotate__1emAq 1s infinite linear;
            animation: Item_rotate__1emAq 1s infinite linear;
}

/* CHILDREN */

.Item_ChildrenContainer__enhBj{
    box-sizing: border-box;
    width: 100%;
}

.Item_Children__1hrLf{
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: var(--margin-main);
    max-height: auto;
    box-sizing: border-box;
    align-items: flex-start;
    align-content: flex-start;
}

.Item_ExpandedChildren__1c-su{
    position: absolute;
    bottom: 0;
    left: 0;
    max-height:100%;
    min-height: 100%;
    margin: 0;
    box-sizing: border-box;
    background-color: var(--background-color-white);
    overflow: auto;
    border-radius: var(--border-radius-main);
    z-index: 10;
    -webkit-animation: Item_fadeIn__3-zrp .25s forwards;
            animation: Item_fadeIn__3-zrp .25s forwards;
}

@-webkit-keyframes Item_fadeIn__3-zrp {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

@keyframes Item_fadeIn__3-zrp {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }


.Item_OverflowChildren__Wr3QC{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--margin-light) auto;
    padding: var(--padding-main);
    border-radius: var(--border-radius-main);
    background-color: var(--background-color-white);
    cursor: pointer;
    width: 50%;
    height: 3rem;
    box-sizing: border-box;
    /* box-shadow: var(--box-shadow-main); */
    border: var(--border-medium);
}

.Item_OverflowChildren__Wr3QC .Item_ChildTitle__2XVtw{
    margin: 0;
}

.Item_OverflowTitle__2zeky{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    text-align: center;
    /* background-color: white; */
    z-index: 2;
    /* padding: var(--padding-main); */
    margin: 0;
    padding: var(--padding-main);
    background-color: white;
}

.Item_OverflowTitle__2zeky>*{
    box-sizing: border-box;
    max-width: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    border-radius: var(--border-radius-main);
}

.Item_OverflowChildren__Wr3QC:hover{
    background-color: var(--primary-color);
    color: white;
}

.Item_BottomPadding__104Iv{
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 3.5rem;
}

.Item_ExpandedChildren__1c-su~.Item_OverflowChildren__Wr3QC{
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 10;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.Item_ChildContextMenu__2BOjT{
    font-size: var(--font-size-small);
}

.Item_ChildWrapper__2da6s{
    /* flex: 0 1 auto; */
    position: relative;
    display: flex;
    flex-direction: column;
    margin: var(--margin-light);
    box-sizing: border-box;
    width: calc(48% - var(--margin-light) - var(--margin-light));
    border-radius: .25rem;
    border: var(--border-medium);
    background-color: var(--background-color-white);
    cursor: pointer;
    height: auto;
    padding-bottom: var(--padding-light);
    
}

.Item_ChildWrapper__2da6s::before{
    content: '';
    float: left;
    padding-top: 92.5%;
}

.Item_Child__3EBeI{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
}


.Item_ChildIcon__3OFBD{
    border-radius: var(--border-radius-main);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    position: relative;
    order: 1;
}

.Item_ChildTitle__2XVtw{
    position: relative;
    /* flex: 0 1 auto; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: var(--font-size-small);
    order: 2;
    text-align: center;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: auto;
    margin: var(--margin-light);
}

.Item_Empty__37--h{
    background-color: transparent;
    /* height: auto; */
    width: 100%;
    align-self: stretch;
    cursor: auto;

}

.Item_Empty__37--h:only-child{
    height: 0;
    display: none;
}

.Item_HoverContainer__1EKe0{
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: var(--background-color-white-translucent);
    font-size: var(--font-size-small);
}

.Item_Child__3EBeI:hover .Item_HoverContainer__1EKe0{
    visibility: visible;
    opacity: 1;
    transition: opacity .25s;
}


@-webkit-keyframes Item_rotate__1emAq {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes Item_rotate__1emAq {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/* MAX WIDTH OF 500PX IS INTENTIONAL */
@media screen and (max-width: 500px) {
    .Item_ChildWrapper__2da6s{
        flex: 0 1 90%;
        padding-bottom: 0;
    }

    .Item_ChildWrapper__2da6s::before{
        display: none;
    }

    .Item_Child__3EBeI{
        position: relative;
        flex-direction: row;
        align-items: stretch;
        height: 3.5rem;
        padding-right: 1rem; /* FOR THREE DOTS ICON */
    }

    .Item_Child__3EBeI .Item_ChildIcon__3OFBD{
        flex: 0 1 40%;
    }

    .Item_Child__3EBeI .Item_Wrapper__bvKei::before{
        padding-top: unset;
    }

    .Item_ChildTitle__2XVtw{
        flex: 1 0 60%;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        align-self: center;
        /* text-align: left; */
        margin: var(--margin-light);
    }

    .Item_ChildTitle__2XVtw span{
        max-width: 100% !important;
        word-wrap: break-word !important;
    }

    .Item_Child__3EBeI .Item_ThreeDots__w-_gj{
        margin-right: 0;
        padding: 0;
    }

    .Item_Child__3EBeI .Item_ThreeDots__w-_gj svg{
        width: 1rem;
    }
}
.EmptyView_Container__KmLgx{
    position: absolute;
    height: auto;
    /* max-height: 50vh; */
    width: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* overflow: hidden; */
}

.EmptyView_Container__KmLgx .EmptyView_Image__6R50l{
    width: auto;
    margin: auto;
    top: 0;
    left: 0;
    -webkit-transform: unset;
            transform: unset;
}

.EmptyView_Actions__1ySTX{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.EmptyView_Button__3YZzP{
    position: relative;
    display: flex;
    flex-direction: row !important;
    padding: var(--padding-main);
    margin: var(--margin-main);
}

.EmptyView_Button__3YZzP svg{
    margin: var(--margin-light);
}

.EmptyView_Confirm__1BpbW{
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.EmptyView_Loader__3_i2I{
    position: relative !important;
}
.IllustrationWrapper_Wrapper__26ohR{ 
    position: relative;
    height: auto;
    width: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    /* overflow: hidden; */
}

.IllustrationWrapper_Image__3p9qH{
    max-width: 100%;
    max-height: 90%;
    object-fit: scale-down;
    object-position: center;
    max-height: 50vh;
    text-align: center;
}

.IllustrationWrapper_Text__1sVJL{
    font-weight: 400;
    text-align: center;
}
.Dots_Dots__2d3Xu {
  /* margin: 100px auto 0; */
  width: 70px;
  height: 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-spinner);
}

.Dots_Dots__2d3Xu > div {
  width: 20%;
  height: 20%;
  margin: 5%;
  background-color: var(--background-color-white);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: Dots_sk-bouncedelay__1vs_b 1.4s infinite ease-in-out both;
  animation: Dots_sk-bouncedelay__1vs_b 1.4s infinite ease-in-out both;
}

.Dots_Dots__2d3Xu .Dots_bounce1__3KgfG {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.Dots_Dots__2d3Xu .Dots_bounce2__1wTDT {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes Dots_sk-bouncedelay__1vs_b {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes Dots_sk-bouncedelay__1vs_b {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
body{
    font-family: 'proxima-nova','PT Sans', sans-serif;
    line-height: 1.15;
    scroll-behavior: smooth;
    font-weight: 300;
    font-size: var(--font-size-main);
    color: var(--text-color-dark);
}

.App_App__16ZpL{
    position: relative;
    height: 100%;
    width: 100%;
}

.App_App__16ZpL .App_Pending__2xeZB{
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: var(--z-index-spinner);
    /* background-color: var(--background-color-white-translucent); */
}

.App_App__16ZpL .App_Dots__3lBl2{
    background-color: var(--accent-color-primary);
    /* background-color: var(--primary-color); */
}
.modal_Modal__yQSq3{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin: 0;
    z-index: var(--z-index-modal);
    max-width: 50%;
    overflow: auto;
}

.modal_Background__9nBwq{
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: calc(var(--z-index-modal) - 1);
    background-color: var(--background-color-dark-translucent);
}

@media screen and (max-width:1200px) {
    .modal_Modal__yQSq3{
        max-width: 75%;
    }
}

@media screen and (max-width:600px) {
    .modal_Modal__yQSq3{
        max-width: 95%;
    }

}
.ErrorBoundaries_Modal__3jlUJ{
    /* background-color: white; */
    /* z-index: 0; */
    position: fixed;
}

.ErrorBoundaries_Background__j4XMM{
    position: fixed;
    height: 100%;
}

.ErrorBoundaries_ErrorContainer__31Rjz{
    display: block;
    margin: auto;
    background-color: white;
    padding: var(--padding-extra-heavy);
    border-radius: var(--border-radius-main);

    width: 100%;
    max-width: 100%;
}

.ErrorBoundaries_Message__-CPe2{
    display: block;
    margin: var(--margin-heavy) auto;
    text-align: center;
    font-size: var(--font-size-medium-large);
    padding: 0 var(--padding-extra-heavy);
    width: 80%;
    max-width: 100%;
}

.ErrorBoundaries_Message__-CPe2 span{
    display: block;
}

.ErrorBoundaries_ImageContainer__2M5E7{
    width: 100%;
    padding: var(--padding-heavy);
}

.ErrorBoundaries_Image__1ujeo{
    width: 100%;
}

.ErrorBoundaries_Details__eDb9j{

}

@media screen and (max-width: 600px) {
    .ErrorBoundaries_Modal__3jlUJ{
        width: -webkit-max-content;
        width: max-content;
    }

    .ErrorBoundaries_ErrorContainer__31Rjz{
        width: -webkit-max-content;
        width: max-content;
    }

    .ErrorBoundaries_Message__-CPe2{
        width: -webkit-max-content;
        width: max-content;
    }
}
